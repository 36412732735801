<template>
	<div class="personBox">
		<div class="backBtn" @click="onClickLeft"><van-icon name="arrow-left" /></div>
		<!-- <div class="iconBtn" v-if="userInfo && userInfo.superUserId" @click="handleLogout">
			{{ $t('personal.loginOut') }}
		</div>
		<div class="iconBtn" v-else @click="handleLogin">
			{{ $t('personal.loginBtn') }}
		</div> -->
		<div class="cardBox">
			<!-- <div class="avatar">
				<img v-if="userInfo.picture" :src="userInfo.picture" :onerror="imgError">
				<img v-else src="~@/assets/images/common/avatar_temp.png" />
			</div> -->
			<!-- <div class="vipDetail">
				<router-link to="/home/recommend">{{ $t('personal.vipDetail') }}＞</router-link>
			</div> -->
				<div class="leftTitle" @click="showTip">VIP규칙</div>
				<div class="vipIconBox">
					<van-image
						width="40%"
						style="padding-top: 1rem;"
						:src="require('@/assets/images/common/vipIcon.png')"
					/>
					<div class="vipRightIconBox">
						<div class="vipRightIcon">
							<span style="padding-left: 1.8rem;font-size: 0.6rem;" v-if="vipLevel == 'vip0'">{{ $t('personal.commonUser') }}</span>
							<span style="padding-left: 2.2rem;" v-else>{{vipLevel}}</span>
						</div>
						<div class="progressBar">
							<div class="proNow" :style="{width:proNow + '%'}"></div>
						</div>
						<div class="vipTip">
							다음 VIP 등급까지 {{needInt}} 포인트 남았습니다
						</div>
						<div class="vipDesc">
							이 플랫폼에서 GM 코인을 충전하면 포인트를 얻을 수 있으며, 1위안 충전 시 1 성장 값을 얻을 수 있습니다.
						</div>
					</div>
				</div>
			<!-- <van-cell center :label="`${$t('personal.googleEmail')}：${userInfo.email || '***'}`" is-link to="/infos">
				<template #title>
				    <span class="titleLabel">{{ $t('personal.platId') }}：</span>
				    <span class="titleVal">{{ userInfo.superUserId || '***' }}</span>
				  </template>
			  </van-cell> -->
			  <!-- <div class="lineBox">
				<div class="coinLine">
					<i class="coinIcon"></i>
					<p>{{ $t('personal.platCoin') }}：{{ userInfo.platCoin || 0 }}</p>
				</div>
			  </div> -->
		</div>
		
		<div class="footDesc">
			<div class="table_box_pay" style="margin-bottom: 30px">
			  <div class="table_pay">
			    <div class="row_pay" style="border-top: 0">
			      <div class="th_pay">등급</div>
			      <div class="th_pay">기준 점수</div>
			      <div class="th_pay" style="border-right: 0">출석 배수</div>
			    </div>
			    <div class="row_pay1">
			      <div class="td_pay">VIP1</div>
			      <div class="td_pay">20000</div>
			      <div class="td_pay_right">0.5-1</div>
			    </div>
			    <div class="row_pay2">
			      <div class="td_pay">VIP2</div>
			      <div class="td_pay">200000</div>
			      <div class="td_pay_right">0.5-1.5</div>
			    </div>
			    <div class="row_pay1">
			      <div class="td_pay">VIP3</div>
			      <div class="td_pay">600000</div>
			      <div class="td_pay_right">0.5-2.0</div>
			    </div>
			    <div class="row_pay2">
			      <div class="td_pay">VIP4</div>
			      <div class="td_pay">1000000</div>
			      <div class="td_pay_right">0.5-2.5</div>
			    </div>
			    <div class="row_pay1" >
			      <div class="td_pay">VIP5</div>
			      <div class="td_pay">2000000</div>
			      <div class="td_pay_right">1-3.0</div>
			    </div>
			    <div class="row_pay2">
			      <div class="td_pay">VIP6</div>
			      <div class="td_pay">6000000</div>
			      <div class="td_pay_right">1-3.5</div>
			    </div>
			    <div class="row_pay1">
			      <div class="td_pay">VIP7</div>
			      <div class="td_pay">10000000</div>
			      <div class="td_pay_right">1-4.0</div>
			    </div>
			    <div class="row_pay2">
			      <div class="td_pay">VIP8</div>
			      <div class="td_pay">20000000</div>
			      <div class="td_pay_right">1-5.0</div>
			    </div>
			  </div>
			</div>
			<!-- <van-cell-group :border="false">
			  <van-cell :title="$t('footer.useManual')" is-link to="/infos" />
			</van-cell-group> -->
		</div>
		
		<!-- 登录 -->
		<Login :showLogin="openLogin" @loginSuccess="loginSuccess" />
		
		<van-popup v-model:show="tipFlag" round teleport="body" :close-on-click-overlay="false" >
			<div class="guidesBox" v-if="tipFlag">
				<div style="max-height: 70vh;overflow-y: scroll;">
					<div class="tipDescTitle">
						VIP 규칙
					</div>
					<div class="tipDesc">
						<p>1.플레이어가 게임에 접속하여 GM 출석 체크를 하면 기본 보상으로 200GM 코인을 얻을 수 있습니다.
						</p>
						<p>2.공식 패키지로 충전하면 VIP 등급을 올릴 수 있으며, 해당 VIP 등급에 따라 출석 시 GM 코인 배수 보상을 받을 수 있습니다.
						</p>
						<p>3.예를 들어, VIP8이 출석 체크를 할 경우 (VIP8은 1-5배 보상을 랜덤으로 받을 수 있음) 200 * (1-5 랜덤 배수) = 800(4배) GM 코인을 받을 수 있습니다.
						</p>
					</div>
					<div class="btnBox" @click="handleSteps">
						확인
					</div>
				</div>
			</div>
		</van-popup>
		
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRouter } from 'vue-router'
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import Login from '@/components/login'
	import { propUserLogin,getVipInfo } from '@/api/gm.js'
	
	const { proxy } = getCurrentInstance();
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const userInfo = computed(() => {
		return useUserStore().userInfo
	})
	const router = useRouter();
	const openLogin = ref(false)
	const tipFlag = ref(false)
	const vipLevel = ref('VIP')
	const needInt = ref('xxx')
	const proNow = ref(0)
	const handleLogin = () => {
		openLogin.value = true
	}
	const loginSuccess = () => {
		openLogin.value = false
	}
	const handleLogout = () => {
		useUserStore().close().then(() => {
			proxy.$toast('Success')
		})
	}
	const onClickLeft = () => {
		history.back()
	}
	const showTip = () => {
		tipFlag.value = true
	}
	const handleSteps = () => {
		tipFlag.value = false
	}
	const handleRecharge = () => {
		router.push({ path:'/recharge' });
	}
	const imgError = () => {
		var img = event.srcElement;
		img.src = require('@/assets/images/common/avatar_temp.png');
		img.onerror = null;
	}
	const toVipDetail = () =>{
		console.log(1111);
	}
	onMounted(() => {
		const query = router.currentRoute.value.query
		if(query.token){
			propUserLogin(query.token).then((res) => {
				console.log(res)
				useUserStore().setInfo(res.data).then((datas) => {
					// logEvent(analytics, 'login_gm', { des: '盒子登录' })
					proxy.$toast('Success')
				})
			})
		}
		if(userInfo.value.picture){
			getVipInfo(userInfo.value.superUserId).then((res)=>{
				vipLevel.value = res.data.vipLevel
				needInt.value = res.data.vipRule.maxIntegral - res.data.integral
				proNow.value = res.data.progressBar
			})
		}
		
	})
	watch(isMobile, (newVal, oldVal) => {
		if(!newVal){
			router.push({ path:'/' });
		}
	},{ immediate: true })
</script>


<style lang="less" scoped>
	.tipDescTitle{
		font-size: 1.2rem;
		margin-bottom: 1rem;
		font-weight: bolder;
	}
	.tipDesc{
		color: #426667;
		text-align: justify; 
		font-size: 0.9rem;
		line-height: 1.4rem;
	}
	a{
		color: #000;
	  &::after{
	      content: '';display: block;width: 0;height: 2px;background: #51bac0;position: absolute;bottom: 0;left: 50%;transition: all 0.3s ease-in-out;
	  }
	  &:hover{
	      color: #51bac0;
	      &::after{width: 100%;left: 0;}
	    }
	  &.router-link-active{
	      color: #51bac0;
	      &:hover{color: #000000;}
	    }
	  
	}
	.table_pay {
	  position: relative;
	  height: calc(100vh - 440px);
	  overflow-y: auto;
	  box-sizing: border-box;
	  width: 100%;
	  max-width: 90%;
	  border-radius: 15px;
	  border: 0.01335rem solid #6ebfc0;
	  overflow: scroll;
	  margin-left: 5%;
	  .row_pay {
	    display: flex;
	  }
	  .row_pay1 {
	    display: flex;
	    border-top: 0.01335rem solid #6ebfc0;
	  }
	  .row_pay2 {
	    display: flex;
	    border-top: 0.01335rem solid #6ebfc0;
	    background-color: #f5f5f6;
	  }
	  
	  .th_pay {
	    background-color: #50bac0;
	    font-size: ~"min(3.8vw,16px)";
	    color: #000;
	    font-weight: 400;
	    width: 34%;
	    height: 47px;
	    line-height: 48px;
	    text-align: center;
	    display: inline-block;
	    // border-right: 0.01335rem solid #6ebfc0;
	  }
	  
	  .td_pay {
	    width: 34%;
	    text-align: center;
	    font-size: ~"min(3.6vw,15px)";
	    color: #000;
	    display: inline-block;
	    justify-content: center;
	    height: 38px;
	    line-height: 40px;
	    border-right: 0.01335rem solid #6ebfc0;
	  }
	  
	  .td_pay_right {
	    width: 34%;
	    text-align: center;
	    font-size: ~"min(3.6vw,15px)";
	    color: #000;
	    display: inline-block;
	    justify-content: center;
	    height: 38px;
	    line-height: 40px;
	  }
	}
	
</style>
<style lang="less" scoped>
	.backBtn{
		color: #b3ffdc;
		position: absolute;
		top: 4%;
	}
	.personBox{
		background: url('~@/assets/images/common/personal_up_bg.png') no-repeat top;
		background-size: 100%;overflow-y: scroll;
		padding: 6rem 1rem 0;box-sizing: border-box;position: relative;
		.footDesc{
			background: #fff;width: calc(100% + 2rem);margin-left: -1rem;margin-top: 4rem;
			height: calc(100vh - 360px);overflow-y: scroll;min-height: 6.25rem;padding-bottom: 3.125rem;box-sizing: border-box;
		}
		.iconBtn{
			position: absolute;top: 1.25rem;right: 1rem;color: #fff;font-size: 0.9375rem;
		}
		.cardBox{
			background: url('~@/assets/images/common/vipLeftBacV.png') no-repeat center top;
			background-size: 100% 100%;
			height: 11rem;
			width: 100%;border-radius: 0.5rem;position: relative;
			.avatar{
				position: absolute;left: 1.875rem;top: -2rem;z-index: 111;
				width: 4rem;height: 4rem;border: 0.125rem solid #fff;border-radius: 50%;overflow: hidden;
				img{
					width: 100%;
				}
			}
			.leftTitle{
				top: 5%;
				position: absolute;
				right: 1%;
				color: white;
				width: 5rem;
				text-align: center;
			}
			.vipIconBox{
				width: 90%;
				padding-top: 1rem;
				margin: 0 auto;
				display: flex;
			}
			.vipRightIconBox{
				width: 80%;position: relative;
				.vipRightIcon{
					color: #f4f4f4;
					background: url('~@/assets/images/common/vipLevelIcon.png') no-repeat center;
					background-size: 100%;
					position: unset;
					width: 5.2rem;
					font-size: 0.8rem;
					height: 1.6rem;
					margin-top: 0.2rem;
					margin-left: 0.8rem;
					line-height: 1.4rem;
				}
				.progressBar{
					margin-top: 0.4rem;
					width: 85%;
					border-radius:20px;
					background-color: #f8f8f8;
					margin-left: 1rem;
					border: 1px solid #f3ae4e;
					height: 0.4em;
				}
				.vipTip{
					font-size: 0.65rem;
					color: #be843a;
					padding-left: 1rem;
					margin-top: 0.2rem;
				}
				.vipDesc{
					font-size: 0.72rem;
					padding-left: 1.2rem;
					margin-top: 1rem;
					color: #ff5b1a;
				}
			}
			.vipIcon{
				width: 4.2rem;
				height: 1.6rem;
				text-align: center;
				line-height:1.6rem;
				padding-left: 1.2rem;
				color: #f4f4f4;
				font-size: 0.8rem;
				position: absolute;
				left: 28%;
				top: 1%;
				background: url('~@/assets/images/common/vipLevelIcon.png') no-repeat center;
				background-size: 100%;
			}
			.vipDetail{
				font-size: 0.8rem;
				position: absolute;
				right:2%;
				line-height: 1.5rem;
				top: 2%;
				color: #000;
			}
			.lineBox{
				padding: 0 1rem 1rem;box-sizing: border-box;margin: 0.75rem 0;
			}
			.coinLine{
				display: flex;align-items: center;justify-content: center;
				padding: 0.75rem 0.75rem;
				background: url('~@/assets/images/common/line_bg.png') no-repeat center;
				background-size: 100%;
				.coinIcon{
					display: inline-block;width: 1.25rem;height: 1.25rem;
					background: url('~@/assets/images/common/coin_icon.png') no-repeat center;
					background-size: 100%;flex-shrink: 0;margin-right: 0.3125rem;
				}
				p{
					flex: 1;font-size: 0.875rem;
				}
				.rechargeBtn{
					flex-shrink: 0;
					font-size: 0.875rem;
					background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
					padding: 0.25rem 1.25rem;border-radius: 0.3125rem;
				}
			}
			/deep/.van-cell:after{
				border-bottom: none;
			}
		}
	}
</style>